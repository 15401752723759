export const LASTNAME_OFFSET = 0;
export const LASTNAME_SIZE = 3;
export const FIRSTNAME_OFFSET = 3;
export const FIRSTNAME_SIZE = 3;
export const YEAR_OFFSET = 6;
export const YEAR_SIZE = 2;
export const MONTH_OFFSET = 8;
export const MONTH_SIZE = 1;
export const DAY_OFFSET = 9;
export const DAY_SIZE = 2;
export const DATE_OFFSET = YEAR_OFFSET;
export const DATE_SIZE = YEAR_SIZE + MONTH_SIZE + DAY_SIZE;
export const GENDER_OFFSET = DAY_OFFSET;
export const GENDER_SIZE = 1;
export const PLACE_OFFSET = 11;
export const PLACE_SIZE = 4;
export const CRC_OFFSET = 15;
export const CRC_SIZE = 1;
export const CF_SIZE = LASTNAME_SIZE + FIRSTNAME_SIZE + DATE_SIZE + PLACE_SIZE + CRC_SIZE;
