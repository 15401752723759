const INVALID_SURNAME: string = "Provided lastName is not valid, only letters, diacritics and apostrophe allowed";
const INVALID_NAME: string = "Provided name is not valid, only letters, diacritics and apostrophe allowed";
const INVALID_DAY: string = "Provided day is not valid";
const INVALID_GENDER: string = "Provided gender is not valid";
const INVALID_DAY_OR_GENDER: string = "Provided day and/or gender are not valid";
const INVALID_YEAR: string = "Provided year is not valid, only 2 or 4 digit numbers are allowed";
const INVALID_DATE: string = "Provided date is not valid";
const INVALID_PLACE_NAME: string = "Proviced City/Country name is not valid";

export {
    INVALID_DATE,
    INVALID_DAY,
    INVALID_DAY_OR_GENDER,
    INVALID_GENDER,
    INVALID_NAME,
    INVALID_PLACE_NAME,
    INVALID_SURNAME,
    INVALID_YEAR,
};
